<template>
    <div class="myorder">
        <van-nav-bar title="我的订单" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="nav" v-if="orderData.length">
            <van-dropdown-menu>
                <van-dropdown-item v-model="value1" :options="option1" @change="handleOrder" />
                <van-dropdown-item v-model="value2" :options="option2" @change="handleOrder" />
            </van-dropdown-menu>
        </div>
        <div class="list">
            <div class="order_box" v-for="(item, index) in orderData" :key="index" @click="toOrderPage(item.id)">
                <div class="box_header">
                    <div class="left">
                        <van-icon name="underway-o" custom-class="icon" />{{ item.pay_at }}
                    </div>
                    <div class="right">{{ item.status_label }}</div>
                </div>
                <div class="box_content">
                    <van-card :tag="item.product_category_label" :price="item.product_money / 100"
                        :title="item.product_name" :thumb="item.product_cover" :num="item.num" class="product_box">
                    </van-card>
                </div>
                <div class="box_footer">
                    <div class="num">共{{ item.num }}件产品</div>
                    <div class="money">合计：￥ {{ item.pay_money / 100 }} 元</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
import { useRouter } from "vue-router";
export default {
    setup() {
        const router = useRouter();
        const orderData = ref([]);
        const option1 = ref([{
            text: '全部订单',
            value: 0
        },
        {
            text: '已支付',
            value: 1
        },
        {
            text: '已退款',
            value: 2
        },
        ]);
        const value1 = ref(0);
        const option2 = ref([{
            text: '全部类型',
            value: 0
        },
        {
            text: '鲜奶订单',
            value: 1
        },
        {
            text: '酸奶订单',
            value: 2
        },
        {
            text: '奶卡订单',
            value: 3
        },
        {
            text: '系统订单',
            value: 4
        },
        ]);
        const value2 = ref(0);
        onBeforeMount(() => {
            loadOrder()
        });
        const handleOrder = () => {
            loadOrder()
        };
        const loadOrder = () => {
            var value1Data = value1.value
            var value2Data = value2.value
            request({
                url: '/app/user/order',
                method: 'GET',
                params: {
                    pageSize: 100,
                    status: value1Data ? value1Data + 1 : 0,
                    type: value2Data ? value2Data + 1 : 0
                }
            }).then((res) => {
                if (res) {
                    var resData = res.items
                    orderData.value = resData
                }
            })
        };
        const onClickLeft = () => history.back();
        const toOrderPage = (id)=>{
            router.push({name:'OrderPage',query:{orderid:id}})
        };
        return {
            orderData,
            option1,
            value1,
            option2,
            handleOrder,
            onClickLeft,
            toOrderPage,
        }
    },
}
</script>
<style>
.nav{margin-bottom: 10px;}

.order_box{overflow: hidden;padding: 10px;background-color: #ffffff;margin-bottom: 10px;}
.order_box .box_header{font-size: 14px;color: #4a4a4a;padding-bottom: 25rpx;border-bottom: 1px solid #ededed;display: flex;}
.order_box .box_header .icon{margin-right: 15rpx;}
.order_box .box_header .left{flex: 1;}
.order_box .box_header .right{color: #07C160;}
.order_box .box_content{}
.order_box .box_footer{font-size: 28rpx;color: #9a9a9a;display: flex;padding-top: 25rpx;border-top: 2rpx solid #ededed;}
.order_box .box_footer .num{}
.order_box .box_footer .money{flex: 1;text-align: right;color: #ee0a24;font-weight: bold;}

.box_content .product_box{padding: 25rpx 0rpx;background-color: #ffffff;}
</style>